<template>
  <div class="container">
    <content-title :nav="nav"></content-title>
    <el-row>
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="团长">
         <user-select :userid.sync="recommender_id"></user-select>
        </el-form-item>
        <el-form-item label="用户">
         <user-select :userid.sync="userid"></user-select>
        </el-form-item>
        <el-form-item label="佣金类型">
          <el-select v-model="type">
            <el-option label="消费券" value="消费券"> </el-option>
            <el-option label="会员卡" value="会员卡"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="邀请人身份">
          <el-select v-model="recommender_type">
            <el-option label="团长" value="团长"> </el-option>
            <el-option label="用户" value="用户"> </el-option>
            <el-option label="机构" value="机构"> </el-option>
            <el-option label="机构商户" value="机构商户"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">搜索</el-button>
        </el-form-item>
      </el-form>

      <el-table
        :data="payList"
        :cell-style="{ 'text-align': 'center' }"
        :header-cell-style="{ 'text-align': 'center' }"
        v-loading="is_loading"
      >
        <el-table-column label="邀请人" prop="map.recommenderUserInfo.nick_name"></el-table-column>
        <!-- <el-table-column label="手机号" prop="mechanism_id"></el-table-column> -->
        <el-table-column label="邀请人身份" prop="recommender_type"></el-table-column>
        <el-table-column label="被邀请人" prop="map.userinfo.nick_name"></el-table-column>
        <el-table-column label="佣金类型" prop="type"></el-table-column>
        <el-table-column label="金额" prop="cash"></el-table-column>
        <el-table-column label="时间" prop="create_time">
        </el-table-column>
      </el-table>
    </el-row>
    <!-- 分页 -->
    <paging-fy
      @currentPageChange="changeCurrentPage"
      :currentPage="currentPage"
      :total="payTotal"
    ></paging-fy>
  </div>
</template>
  
  <script>
  import userSelect from '../../components/select/userSelect.vue';
export default {
    components:{
        userSelect
    },
  data() {
    return {
      nav: { firstNav: "财务管理中心", secondNav: "佣金明细" },
      mechanism_list: [],
      payList: [],
      mechanismid: null,
      is_loading: true,
      refuseReason: false,
      showReason: false,
      showDialog: false,
      user_mobile: null,
      refuse_contect: "",
      is_loading: false,
      currentPage: 1,
      payTotal: 0,
      temp: {},
      startTime: "",
      endTime: "",
      time: [],
      MecList: {},
      type: "消费券",
      userid:'',
      recommender_id:'',
      recommender_type:''
    };
  },
  created() {
    this.getRefoundList();
  },
  methods: {
    checktime() {
      this.startTime = this.time[0] + " 00:00:00";
      this.endTime = this.time[1] + " 23:59:59";
    },
    getRefoundList() {
      this.is_loading = true;
      let url = "user/userRecommenderIncomeLog/queryManagerListPage";
      this.$axios
        .get(url, {
          params: {
            pageSize: 10,
            currentPage: this.currentPage,
            type: this.type,
            user_id:this.userid || null,
            recommender_id:this.recommender_id || null
          },
        })
        .then((res) => {
          this.payList = res.data.data.rows;
          this.payTotal = res.data.data.total;
          this.is_loading = false;
        });
    },
    search() {
      this.currentPage = 1;
      this.getRefoundList();
    },
    changeCurrentPage(v) {
      this.currentPage = v;
      this.getRefoundList();
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.refuseReason = false;
          this.showReason = false;
          this.temp = {};
          done();
        })
        .catch((_) => {});
    },
  },
};
</script>
  
  <style></style>
  